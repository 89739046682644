export const ADMIN_ROUTE = "/admin";
export const LOGIN_ROUTE = "/login";
export const REGISTER_ROUTE = "/register";
export const HOME_ROUTE = "/";
export const PURLIST_ROUTE = "/purlist";
export const ADDPUR_ROUTE = "/addpur";
export const EDITPUR_ROUTE = "/editpur";
export const EDIT_TAGS_ROUTE = "/edittags";
export const EDIT_NEEDS_ROUTE = "/editneeds";
export const EDIT_FREQS_ROUTE = "/editfreqs";
export const TEST_ROUTE = "/test";
